// Add your JS customizations here

// Show more
//

document.addEventListener("DOMContentLoaded", function () {
	let showMoreBtn = document.querySelector("[data-truncate-button]");
	let content = document.querySelector("[data-truncate]");

	if (
		typeof showMoreBtn != "undefined" &&
		content != null &&
		typeof content != "undefined" &&
		content != null
	) {
		showMoreBtn.addEventListener("click", () => {
			content.classList.toggle("truncated");

			showMoreBtn.textContent = content.classList.contains("truncated")
				? "Read more"
				: "Read less";
		});
	}
});


// Colours
// const stc = require('string-to-color');
//
// document.addEventListener("DOMContentLoaded", function () {
//   const colours = document.querySelectorAll('[data-color]');
//
//   for(let colour of colours) {
//     const dataColor = colour.dataset.color;
//     colour.style.backgroundColor = stc(dataColor);
//   }
// });

// Enable Tooltip
document.addEventListener("DOMContentLoaded", function () {
	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new understrap.Tooltip(tooltipTriggerEl))
});

// Multi Slider
import EmblaCarousel from 'embla-carousel'

export const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
  prevBtn.addEventListener('click', embla.scrollPrev, false);
  nextBtn.addEventListener('click', embla.scrollNext, false);
};

const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
  return () => {
    if (embla.canScrollPrev()) prevBtn.removeAttribute('disabled');
    else prevBtn.setAttribute('disabled', 'disabled');

    if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');
    else nextBtn.setAttribute('disabled', 'disabled');
  };
};

function setupEmblaCarousel (emblaNode, options) {
	// const wrap = emblaNode.querySelector(".embla");
	const viewPort = emblaNode.querySelector(".embla__viewport");
	const prevBtn = emblaNode.querySelector(".embla__button--prev");
	const nextBtn = emblaNode.querySelector(".embla__button--next");
	const embla = EmblaCarousel(viewPort, options);
	const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

	setupPrevNextBtns(prevBtn, nextBtn, embla);

	embla.on("select", disablePrevAndNextBtns);
	embla.on("init", disablePrevAndNextBtns);
}


document.addEventListener("DOMContentLoaded", function () {
	const options = { loop: true, align: "start", skipSnap: false};
	const emblaNodes = [].slice.call(document.querySelectorAll(".embla"));
	emblaNodes.map(emblaNode =>
  	setupEmblaCarousel(emblaNode, options)
	);
});

// Facetwp pager scroll to top
/* (function ($) {
	$(document).on("facetwp-loaded", function () {
		if (FWP.loaded) {
			$("html, body").animate(
				{
					// scrollTop: $(".facetwp-template").offset().top,
					scrollTop: $("body").offset().top,
				},
				100
			);
		}
	});
})(jQuery); */
document.addEventListener('facetwp-refresh', function() {
  if (FWP.soft_refresh === true) {
    FWP.enable_scroll = true;
  } else {
    FWP.enable_scroll = false;
  }
});

document.addEventListener('facetwp-loaded', function() {
  if (FWP.enable_scroll === true) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
});
